import React from 'react';
import "./Brand.scss"

const Brand = () => {
    return (
        <div onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            // window.location.href = window.origin
            window.history.pushState({}, null, window.origin);

        }} className="Brand">
            <h1>CCAC</h1>
        </div>
    );
};

export default Brand;