import React, { useState } from 'react';
import Brand from '../Brand/Brand';
import "./Navbar.scss"
import menu from "../../images/menu.png"
import useMediaQuery from '../../../hooks/useMediaQuery';

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width: 600px)')

    return (
        <div className="Navbar">
            <Brand/>
            <img className="menu-icon" src={menu} alt="Open Menu" onClick={() => {
                setMenuOpen(prevValue => !prevValue)
            }}/>
            <ul className={(!isMobile || (isMobile && menuOpen)) ? "open" : ""} onClick={() => {
                setMenuOpen(false)
            }}>
                <li><a href="#about">About</a></li>
                <li><a href="#services">Services</a></li>
                <li><a href="#process">Our Process</a></li>
                <li><a href="#contact">Contact</a></li>
            </ul>
            
        </div>
    );
};

export default Navbar;