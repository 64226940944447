import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "./Form.scss";
import { ErrorMessage } from "@hookform/error-message";
import emailjs from "emailjs-com";

const INDUSTRIES = [
  "Energy",
  "Mining",
  "Infastructure",
  "Agribusiness",
  "Digital / IT",
  "Education",
  "Banking / Finance",
  "Tourism and Hospitality",
];

const Form = () => {
  // 0 = not sent
  // 1 = sending
  // 2 successfully sent
  const [sent, setSent] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    emailjs
      .send(
        "service_e8cneih",
        "template_ncf308s",
        {
          message: data.About,
          company: data.Company,
          name: data.Name,
          phone: data.Phone,
          email: data.Email,
          industry: data.Industry,
        },
        "user_wgEfOov1MSiidC5c9ccon"
      )
      .then(
        (response) => {
          console.log(response);
          setSent(true);
        },
        (error) => {
          alert(
            "The contact form has failed! Please contact us at dmz.val@gmail.com"
          );
        }
      );
  };
  console.log("errors", errors);

  return (
    <form className="Form" onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div>
          <label for="Name">Name</label>
          <input
            id="Name"
            type="text"
            placeholder="Name"
            {...register("Name", { required: "Name is required" })}
          />
          <ErrorMessage
            errors={errors}
            name="Name"
            render={({ message }) => <p className="error">{message}</p>}
          />
        </div>

        <div>
          <label for="Company">
            Company <span className="light">(optional)</span>
          </label>
          <input
            type="text"
            placeholder="Company"
            {...register("Company", {})}
          />
        </div>
      </div>

      <div className="row">
        <div>
          <label for="Email">Email</label>
          <input
            type="text"
            placeholder="Email"
            {...register("Email", {
              required: "Email is required",
              pattern: { value: /^\S+@\S+\.\S+$/i, message: "Invalid email" },
            })}
          />
          <ErrorMessage
            errors={errors}
            name="Email"
            render={({ message }) => <p className="error">{message}</p>}
          />
        </div>
        <div>
          <label for="Phone">
            Phone <span className="light">(optional)</span>
          </label>
          <input
            type="text"
            placeholder="Phone"
            {...register("Phone", {
              pattern: {
                value:
                  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i,
                message: "Invalid phone number",
              },
            })}
          />
          <ErrorMessage
            errors={errors}
            name="Phone"
            render={({ message }) => <p className="error">{message}</p>}
          />
        </div>
      </div>
      <div className="Industries">
        <label for="Industry">Industry</label>
        {[...INDUSTRIES, "Other"].map((industry) => (
          <div>
            <input
              {...register("Industry", { required: "Industry is required" })}
              type="radio"
              value={industry}
            />
            <label for={industry}>{industry}</label>
          </div>
        ))}
        <ErrorMessage
          errors={errors}
          name="Industry"
          render={({ message }) => <p className="error">{message}</p>}
        />
      </div>
      <div className="About">
        <label for="About">About your project</label>
        <textarea
          placeholder="Brief description of your interest and comments for the CCAC"
          rows={10}
          {...register("About", {})}
        />
      </div>
      <button type="submit">{sent ? "Submitted!" : "Submit"}</button>
      {sent && <p>Form submitted! We will be in touch with you shortly.</p>}
    </form>
  );
  //   return (
  //     <form>
  //       <label for="industry">Industry</label>
  //       <br />
  //       <input list="industry" name="industry" />
  //       <div onChange={(e) => {
  //           console.log(e.target.value)
  //       }}>
  //         {[...INDUSTRIES, "Other"].map((industry) => (
  //           <>
  //             <label for={industry}>{industry}</label>
  //             <input type="radio" name="industries" value={industry} />
  //           </>
  //         ))}
  //       </div>
  //       <br />
  //       <br />

  //       <label>
  //         Brief description of your interest and comments for the CCAC
  //       </label>
  //       <br />
  //       <textarea style={{ width: "100%" }} rows={10}></textarea>
  //       <br />
  //       <br />
  //       <label>Email</label>
  //       <br />
  //       <input type="email"></input>
  //     </form>
  //   );
};

export { Form, INDUSTRIES };
